<template>
  <v-container>
    <v-row>
      <v-col align="right" justify="right">
        <div v-if="!gaAuthed">
          <div>When asked, please authenticate with Google account: <strong>shazadmin@ghi.ngo</strong> (PW: ShazaCinAdmin54321)</div>
          <div id="embed-api-auth-container"></div>
        </div>
        <v-btn v-if="gaAuthed" @click="signOut">analytics signout</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <div id="chart-container"></div>
      </v-col>
      <v-col cols="12" md="6">
        <!-- <h3>Sessions in the last 30 days:</h3> -->
        <div id="chart-container-2"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  (function (w, d, s, g, js, fs) {
    g = w.gapi || (w.gapi = {}); g.analytics = { q: [], ready: function (f) { this.q.push(f); } };
    js = d.createElement(s); fs = d.getElementsByTagName(s)[0];
    js.src = 'https://apis.google.com/js/platform.js';
    fs.parentNode.insertBefore(js, fs); js.onload = function () { g.load('analytics'); };
  }(window, document, 'script'));


  export default {
    components: {

    },
    data() {
      return {
        gapi,
        gaAuthed: false
      }
    },
    computed: {
    },
    methods: {
      isGaAuthed() {
        return this.gapi?.analytics?.auth?.isAuthorized() || "unknown"
      },
      async initAuth() {
        const self = this
        gapi.analytics.ready(async function () {

          /**
           * Authorize the user immediately if the user has already granted access.
           * If no access has been created, render an authorize button inside the
           * element with the ID "embed-api-auth-container".
           */
          gapi.analytics.auth.authorize({
            container: 'embed-api-auth-container',
            clientid: '564850120431-v91s998mme1afr1scbkpp744t3k0ce91.apps.googleusercontent.com'
          });

          await self.renderCharts()
          console.info("renderCharts was executed!")
          setTimeout(() => {
            if (self.gapi?.analytics?.auth?.isAuthorized()) {
              self.gaAuthed = true
            }
          }, 3000)
        })
      },
      async renderCharts() {
        var dataChart = new gapi.analytics.googleCharts.DataChart({
          query: {
            metrics: 'ga:sessions',
            dimensions: 'ga:date',
            'start-date': '30daysAgo',
            'end-date': 'yesterday'
          },
          chart: {
            container: 'chart-container',
            type: 'LINE',
            options: {
              width: '100%',
              title: 'Sessions over the past 30 days.',
              fontSize: 12
            }
          }
        });

        var dataChart2 = new gapi.analytics.googleCharts.DataChart({
          query: {
            metrics: 'ga:pageviews',
            dimensions: 'ga:date',
            'start-date': '30daysAgo',
            'end-date': 'yesterday'
          },
          chart: {
            container: 'chart-container-2',
            type: 'LINE',
            options: {
              width: '100%',
              title: 'Page views over the past 30 days.',
              fontSize: 12
            }
          }
        });
        let ids = "ga:237442155"
        dataChart.set({ query: { ids: ids } }).execute();
        dataChart2.set({ query: { ids: ids } }).execute();
      },
      signOut() {
        console.info(`Here at signout: `, gapi.analytics.auth)
        gapi.analytics.auth.signOut();
      }
    },
    mounted() {
      this.initAuth()
    }
  }
</script>

<style lang="scss" scoped>

</style>